<template>
  <div class="auth-root">
    <b-spinner 
      v-if="isLoading" 
      class="d-block mx-auto text-primary my-2" 
    />
    <auth-layout 
      v-else 
      :community="community"
      :auth-api-url="authApiUrl"
    >
      <router-view />
    </auth-layout>
  </div>
</template>

<script>
import { selectLangFromCommunity } from '@/@core/utils/collective'
import { translateTranslationTable } from '@/@core/libs/i18n/utils'
import { AccessTokenKey } from '@/store/auth/auth.store.constants'
import AuthLayout from './layouts/AuthLayout.vue'

export default {
  name: 'AuthRoot',

  components: {
    AuthLayout
  },

  data() {
    return {
      isLoading: true,
      community: null
    }
  },

  computed: {
    communitySlug() {
      const slugParam = this.$route.params.communityId;
      
      // Enhanced validation
      if (!slugParam || 
          slugParam === 'undefined' || 
          slugParam === 'null' || 
          slugParam === 'error' || 
          slugParam === '') {
        // console.log('AuthRoot - Invalid or missing community slug, using empty string');
        return '';
      }
      
      return slugParam;
    },
    authApiUrl() {
      return (process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios.com') + '/api/v1/';
    }
  },

  async created() {
    // console.log('AuthRoot - initCommunity started');
    // console.log('AuthRoot - communitySlug:', this.communitySlug, 'type:', typeof this.communitySlug);
    
    if(!this.communitySlug || this.communitySlug === ''){
      this.isLoading = false;
      // console.log('AuthRoot - initCommunity - no valid community slug found, setting isLoading to false');
      // Set a minimal default community object
      this.community = {
        name: 'Nectios',
        slug: '',
        customization: {
          theme: { login: { backgrounds: [] } },
          auth_image_url: {},
          auth_header_image: null
        }
      };
      return;
    }

    try {
      const authApiUrl = this.authApiUrl;
      // console.log('AuthRoot - initCommunity - fetching community data from:', `${authApiUrl}login?communitySlug=${this.communitySlug}`);
      
      const response = await fetch(
        `${authApiUrl}login?communitySlug=${this.communitySlug}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...this.getAuthHeaders()
          },
          credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
          mode: 'cors'
        }
      );
      
      // console.log('AuthRoot - initCommunity - response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('AuthRoot - initCommunity - error response:', errorData);
        
        if (response.status === 401 || response.status === 403) {
          // console.log('AuthRoot - initCommunity - unauthorized, logging out');
          this.$store.dispatch('logout');
          this.$router.push({
            name: 'auth-login',
            params: { communityId: this.communitySlug }
          });
          return;
        }
        throw new Error(errorData?.message || `Failed to fetch community data: ${response.status}`);
      }

      const data = await response.json();
      // console.log('AuthRoot - initCommunity - received data:', data);
      
      if (!data || !data.data) {
        console.error('AuthRoot - initCommunity - invalid response format:', data);
        throw new Error('Invalid response format from server');
      }

      this.community = data.data;
      // console.log('AuthRoot - initCommunity - community object structure:', 
      // Object.keys(this.community).map(key => `${key}: ${typeof this.community[key]}`).join(', '));
      // console.log('AuthRoot - initCommunity - community customization:', this.community.customization);
      
      // Ensure there's a customization object to avoid null reference errors
      if (!this.community.customization) {
        // console.log('AuthRoot - initCommunity - adding default customization object');
        this.community.customization = {
          theme: {
            login: {
              backgrounds: []
            }
          },
          auth_image_url: {},
          auth_header_image: null
        };
      }

      // Set language
      selectLangFromCommunity(this.community);

      // Set page title
      document.title = this.translate(this.community.name);

      // Set metadata if exists
      if (this.community.headMeta) {
        this.setMetadata(this.community.headMeta);
      }
    } catch (error) {
      console.error('AuthRoot - initCommunity - error fetching community:', error);
      if (error.message.includes('Failed to fetch')) {
        console.error('AuthRoot - initCommunity - CORS or Network Error - Please check API availability and CORS configuration');
      }
      
      // Set a default community object to avoid undefined errors
      this.community = {
        name: this.communitySlug,
        slug: this.communitySlug
      };
      
      // console.log('AuthRoot - initCommunity - using fallback community:', this.community);
    } finally {
      // console.log('AuthRoot - initCommunity - completed, isLoading set to false');
      this.isLoading = false;
    }
  },

  methods: {
    getAuthHeaders() {
      const token = localStorage.getItem(AccessTokenKey) || this.$store.state.auth?.token;
      return token ? { 'Authorization': `Bearer ${token}` } : {};
    },

    // Separate method for setting metadata
    setMetadata(metadata) {
      const metaTags = {
        'author': metadata.name_author,
        'description': metadata.name_description,
        'keywords': metadata.name_keywords,
        'twitter:title': metadata.name_twitter_title,
        'twitter:description': metadata.name_twitter_description
      };

      // Set meta tags
      Object.entries(metaTags).forEach(([name, content]) => {
        if (content) {
          const elements = document.getElementsByName(name);
          if (elements.length > 0) {
            elements[0].content = content;
          }
        }
      });

      // Set OpenGraph tags
      const ogTags = {
        'ogtitle': metadata.property_og_title,
        'ogdescription': metadata.property_og_description
      };

      Object.entries(ogTags).forEach(([id, content]) => {
        if (content) {
          const element = document.getElementById(id);
          if (element) {
            element.content = content;
          }
        }
      });
    },

    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field)
    }
  },

  provide() {
    // In Vue 2, we can return an object with get/set to make it reactive
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios.com',
      community: {
        get: () => this.community
      }
    }
  }
}
</script> 