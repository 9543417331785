<template>
  <div class="vertical-layout vertical-menu-modern blank-page navbar-floating footer-static">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <!-- <div class="header-navbar-shadow"></div> -->
      <div class="content-wrapper">
        <div class="content-header row"></div>
        <div class="content-body">
          <div class="auth-wrapper auth-v2">
            <div class="auth-inner row m-0">
              <!-- Left Column: Image -->
              <div 
                class="d-none d-lg-flex col-lg-8 p-0 auth-left-col"
              >
                <div
                  class="auth-bg-container"
                  :style="sideImageStyle"
                ></div>
              </div>

              <!-- Right Column: Form -->
              <div 
                class="d-flex col-lg-4 align-items-top auth-bg auth-vertical-bar position-relative"
              >
                <language-selector 
                  v-if="showLangSelector"
                  :community="community" 
                />
                <div class="w-100 p-0">
                  <div class="auth-header-container">
                    <img 
                      v-if="headerImage"
                        class="auth-header-image"
                        :src="headerImage"
                        alt="Auth Header Image"
                    />
                    <div 
                      v-if="logoImage"
                      class="auth-logo-title-container"
                    >
                      <img 
                        class="auth-logo-image"
                        :src="logoImage"
                        alt="Auth Logo Image"
                      />
                      <div class="auth-title-text">
                        {{ communityName || "Nectios" }}
                      </div>
                      <div v-if="communityHeadline" class="auth-subtitle-text">
                        {{ communityHeadline }}
                      </div>
                    </div>
                  </div>
                  <div class="auth-form-container">
                      <slot></slot>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BannerCookies from '@/@core/components/BannerCookies.vue'
import LanguageSelector from '@/auth/components/LanguageSelector.vue'
import { getImageResource } from '@/@core/utils/image-utils'
import { translateTranslationTable } from '@/@core/libs/i18n/utils'

export default {
  name: 'AuthLayout',
  
  components: {
    // BannerCookies,
    LanguageSelector
  },

  props: {
    community: {
      type: Object,
      required: false,
      default: null
    },
    authApiUrl: {
      type: String,
      required: true
    }
  },

  provide() {
    return {
      authApiUrl: this.authApiUrl
    }
  },

  data() {
    return {
      isNexosInfra: process.env.VUE_APP_CUSTOMER === 'nexos'
    }
  },

  computed: {
    isNexos() {
        // console.log('community', this.community);
        const isNexosCommunityKey = this.community?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
        // console.log('isNexosCommunityKey', isNexosCommunityKey);
        // console.log('this.isNexosInfra', this.isNexosInfra);
        
        return isNexosCommunityKey || this.isNexosInfra;
    },
    sideImage() {
      if(this.isNexos){
        return '/assets/customers/nexos/nexos-banner-1.png';
      }

      // Check for theme backgrounds first
      const backgrounds = this.communityCustomizations?.theme?.login?.backgrounds || [];
      // console.log('backgrounds', backgrounds)
      if (backgrounds.length) {
        console.log('backgrounds', backgrounds)
        return getImageResource(backgrounds[0]); // Use first background image if available
      }

      // console.log('background not found, lets see...')

      // Fallback to auth_image_url logic
      const currentLocale = this.$store.state.locale.currentLocale || 'es'
      const routePath = this.$route.path.split('/').pop() || '/'
      const authImageUrls = this.communityCustomizations?.auth_image_url || {}
      // console.log('authImageUrls', authImageUrls)
      // console.log('routePath', routePath)
      // console.log('currentLocale', currentLocale)
      // const routePathFull = '/' + routePath;
      const routePathFull = '/login'; // for the moment we use the login image always
      const routeImages = authImageUrls[routePathFull] || {}
      const localeImage = this.translate(routeImages)
      // console.log('localeImage', localeImage)

      return localeImage || '/assets/images/communities-login/login-community-default.svg'
    },
    backgroundStyle() {
      const backgrounds = this.communityCustomizations?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    logoImage() {
        if(this.isNexos){
            return null;
        }
        return this.community?.logoURL || null;
    },
    headerImage() {
        if(this.isNexos){
            return '/assets/customers/nexos/nexos-auth-header.png';
        }
        return this.communityCustomizations?.auth_header_image || null;//'/assets/images/auth-header-short.jpg';
    },
    communityName() {
        return this.community ? this.translate(this.community.name) : "Nectios";
    },
    communityHeadline() {
        return this.community ? this.translate(this.community.headline) : null;
    },
    sideImageStyle() {
      return {
        backgroundImage: `url(${this.sideImage})`
      }
    },
    communityCustomizations() {
        return this.community?.community_customizations || null;
    },
    communitySlug() {
        return this.community?.slug || null;
    },
    showLangSelector() {
      if(this.isNexos){
        return false;
      }
      // console.log('community in AuthLayout:', this.community);
      const availableLanguages = this.community?.availableLanguages || []
      // console.log('availableLanguages', availableLanguages)
      return availableLanguages.length > 1;
    },
    themeStyles() {
      if (!this.communityCustomizations?.theme) return {}

      const { primaryColor, secondaryColor } = this.communityCustomizations.theme
      
      return {
        '--primary-color': primaryColor || 'hsl(12,88%,47%)',
        '--secondary-color': secondaryColor || 'hsl(60,3%,7%)',
        // Convert HSL to components for other uses
        '--primary-color-h': primaryColor ? primaryColor.match(/\d+/g)[0] : '12',
        '--primary-color-s': primaryColor ? primaryColor.match(/\d+/g)[1] + '%' : '88%',
        '--primary-color-l': primaryColor ? primaryColor.match(/\d+/g)[2] + '%' : '47%',
        '--secondary-color-h': secondaryColor ? secondaryColor.match(/\d+/g)[0] : '60',
        '--secondary-color-s': secondaryColor ? secondaryColor.match(/\d+/g)[1] + '%' : '3%', 
        '--secondary-color-l': secondaryColor ? secondaryColor.match(/\d+/g)[2] + '%' : '7%'
      }
    },
    communityBannerImage() {
      // console.log('communityBannerImage computed');
      if (!this.community) {
        // console.log('communityBannerImage computed - no community, returning null');
        return null;
      }
      
      // Check if theme is defined with the backgrounds property
      const loginTheme = this.communityCustomizations?.theme?.login;
      if (!loginTheme || !loginTheme.backgrounds || !loginTheme.backgrounds.length) {
        // console.log('communityBannerImage computed - no theme backgrounds, returning null');
        return null;
      }
      
      // Get the background image
      const backgroundImage = loginTheme.backgrounds[0];
      // console.log('communityBannerImage computed - returning background image:', backgroundImage);
      return backgroundImage;
    },
    
    communityLogo() {
      // console.log('communityLogo computed');
      
      if (!this.community) {
        // console.log('communityLogo computed - no community, returning default logo');
        return '/assets/images/brand/nectios-favicon.png';
      }
      
      // Priority 1: Check for logo in customization
      const customizationLogo = this.communityCustomizations?.logo_url;
      if (customizationLogo) {
        // console.log('communityLogo computed - using customization logo:', customizationLogo);
        return customizationLogo;
      }
      
      // Priority 2: Check for logo in the community's images array
      const images = this.community.images || [];
      const logoImage = images.find(img => img.key === 'logo');
      if (logoImage && logoImage.url) {
        // console.log('communityLogo computed - using community images logo:', logoImage.url);
        return logoImage.url;
      }
      
      // Priority 3: Use default logo
      // console.log('communityLogo computed - no logo found, using default');
      return '/assets/images/brand/nectios-favicon.png';
    },
  },

  watch: {
    'communityCustomizations.theme': {
      immediate: true,
      handler(newTheme) {
        if (newTheme) {
          this.applyThemeStyles()
        }
      }
    }
  },

  created() {
    this.applyThemeStyles()
    this.applyTheme()
  },

  mounted() {
    this.applyThemeStyles()
    this.applyTheme()
  },

  methods: {
    applyTheme() {
      if (this.isNexos) {
        // Add nexos theme class to body
        document.body.classList.add('auth-theme')
      }
    },
    applyThemeStyles() {
      const styles = this.themeStyles
      Object.entries(styles).forEach(([property, value]) => {
        document.documentElement.style.setProperty(property, value)
      })
      // Reapply theme if needed
      if (this.isNexos) {
        this.applyTheme()
      }
    },

    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field)
    }
  },

  beforeDestroy() {
    if (this.isNexos) {
      document.body.classList.remove('auth-theme')
    }
    // Reset theme custom properties
    Object.keys(this.themeStyles).forEach(property => {
      document.documentElement.style.removeProperty(property)
    })
  }
}
</script>

<style lang="scss">
/* Global styles for auth theme */
.auth-theme {
  a, .auth-link {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer;

    &:hover {
      color: var(--secondary-color, black) !important;
      opacity: 0.8;
    }
  }

  button {
    color: black !important;
  }
}
</style>

<style lang="scss" scoped>
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;

  .auth-inner {
    width: 100%;
    height: 100vh;
    display: flex;
  }
}

.auth-header-container {
  width: 100%;
  padding: 0;
}

.auth-header-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

.auth-logo-image {
  width: 100px;
  height: auto;
  height: auto;
  display: block;
  object-fit: contain;
  margin: 3rem auto 1rem;
}

.auth-title-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem auto 0;
}

.auth-subtitle-text {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  margin: 1rem auto;
}

.auth-form-container {
  padding: 3rem;

  :deep(.auth-btn-container) {
    margin-top: 4rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  :deep(.auth-btn) {
    border-radius: 5rem;
    width: 308px;
    height: 50px;
    color: var(--secondary-color) !important;
    
    &.auth-btn-primary {
      background-color: var(--primary-color) !important;
    }
    
    &.auth-btn-secondary {
      border-color: var(--secondary-color) !important;
      background-color: transparent !important;
    }
  }
}

.auth-left-col {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.auth-bg-container {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
}

.auth-vertical-bar {
  position: relative;
  padding: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
}

@media (min-width: 992px) {
  .auth-bg-container {
    background-size: 100% auto;
    transition: background-size 0.3s ease;
  }
}

.cookies-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999999 !important;
    padding: 25px 95px !important;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100vw;
    box-shadow: 0 0 20px 3px rgb(31 32 48 / 15%);
}
.cookies-banner .btn {
    border-radius: 0;
    padding: 20px;
    min-width: 156px;
}
@media (max-width: 992px) {
    .cookies-banner {
        flex-wrap: wrap;
        padding: 25px 30px !important;
    }
}
.privacy-checkbox {
    margin-left: 5px;
}

.auth-header {
    /* background-image: url('/assets/customers/nexos/nexos-auth-header.png');
    background-size: cover;
    background-position: center; */
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 50px;
    margin-bottom: 1rem;
}
.auth-header-short {
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 50px;
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .auth-legal-container {
        border-left: 1px solid #f4f4f4; 
    }
}

.auth-header img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: bottom;
}

.auth-main-title {
    font-size: 2.5rem !important;
}

.auth-link {
  color: black;
  // color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}

.auth-vertical-bar {
    padding: 0 !important;
}

.auth-form-container {
    padding: 3rem;
}

.form-control {
  border: 1px solid #d8d6de;
  padding: 0.571rem 1rem;
  height: calc(1.25em + 1.4rem + 1px);
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.357rem;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  
  &:focus {
    color: #6e6b7b;
    background-color: #fff;
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}

.input-group-merge {
  .form-control {
    &:not(:last-child) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group-append {
    .input-group-text {
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
    }
  }
}

.language-selector-container {
  position: relative;
  margin: 1rem;
  height: 40px;
}

// Comment out or remove the :deep(.language-selector) style temporarily
/* :deep(.language-selector) {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
} */

// Add any additional styling needed
</style> 